import React, {useRef, useEffect, useState} from 'react'
import {graphql} from 'gatsby'
import {isMobile, debounce, generateKey, filterOutUnlistedDocs} from '../lib/helpers'
import {filterOutDocsWithoutSlugs, inBrowser} from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import '../styles/main.scss'

import NavigationTabs from '../components/navigation-tabs'
import HomepageProject from '../components/homepage-project'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      keywords
    }    
    projects: sanityWorkPage(_id: {regex: "/(drafts.|)workPage/"}) {
      projects {
        id
        unlisted
        previewImage {
          asset {
            _id
            metadata {
              dimensions {
                aspectRatio
              }
              palette {
                dominant {
                  background
                }
              }
            }             
          }
          alt
        }
        slug {
          current
        }
      }
    } 
  }
`

const IndexPage = props => {
  const {data, errors} = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  
  const site = (data || {}).site

  //Randomly shuffle the order of the images
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[j];
        array[j] = array[i];
        array[i] = temp;
    }
    return array;
  }
  const projectNodes = data.projects.projects.filter(filterOutDocsWithoutSlugs).filter((project) => project.previewImage && project.previewImage.asset).filter(filterOutUnlistedDocs);
  const shuffledProjects = shuffleArray(projectNodes);  
  const projectSubset = shuffledProjects.slice(0,5);

  
  //Measuring stuff for the image layouts
  const workContainer = useRef(null);
  const [workHeight, setWorkHeight] = useState(null);
  const [dimensions, setDimensions] = useState({ 
    height: inBrowser() ? window.innerHeight : undefined,
    width: inBrowser() ? window.innerWidth : undefined
  })
  const getDimensions = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth
    })
    setWorkHeight(workContainer.current.getBoundingClientRect().height);
  }
  useEffect(() => {
    if (!isMobile()) {
      getDimensions()
    }
    const debouncedHandleResize = debounce(function handleResize() {
      if (!isMobile()) {
        getDimensions();
      }
    }, 350)
    window.addEventListener('resize', debouncedHandleResize)
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout isHome={true}>
      <SEO title={`Home`} description={site.description} keywords={site.keywords} themeColour="black" />
      <Container className="home-page">
        <div className="work-container" ref={workContainer}>
          {projectSubset.map((project, index) => {
            let transformStyle = null;
            if (!isMobile() && workHeight) {
              const maxTransformDistance = workHeight - (dimensions.width / projectSubset.length);
              const randomTransform = Math.floor(Math.random() * (maxTransformDistance - 0 + 1)) + 0;
              transformStyle = {transform: 'translateY(' + randomTransform + 'px)'};
            }
            return (
            <HomepageProject key={generateKey(project.id)} project={project} index={index} transformStyle={transformStyle}/>
          )})}
        </div>
        <div className="work-container -overlay">
          {projectNodes.slice(0, projectSubset.length).map((columnProject) => {
            return (
              <div key={generateKey(columnProject.id)} className="work-column -border"></div>
            )
          })}
        </div>
      </Container>
    </Layout>
  )
}

export default IndexPage
