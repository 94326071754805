import React from 'react'
import {Link} from 'gatsby'
import {getProjectUrl} from '../lib/helpers'
import Figure from '../components/figure'

const HomepageProject = ({project, index, transformStyle}) => {
    return (
        <div className={`work-column ${index}`}>
            <Link to={getProjectUrl(project.slug)} className="project-thumbnail" style={transformStyle}>
            <div className="inner">
                <Figure node={project.previewImage} maxWidth={900} square preventLoadAnimation colourUnderlay />
            </div>
            </Link>
        </div>
    )
}

export default HomepageProject;